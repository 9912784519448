<template>
  <div>
    <MallheaderVue :customstyle="{ background: '#FFFFFF' }" :titlename="'申请商家'"></MallheaderVue>
    <div class="applycontent">
      <!-- <div class="inputbox">
        <van-field
          label-align="right"
          label-width="60"
          v-model="username"
          label="姓名"
          placeholder="请输入姓名"
        />
      </div> -->
      <div class="inputbox marginT16">
        <van-field label-align="right" label-width="60" v-model="mobile" label="电话" placeholder="请输入电话" />
      </div>
      <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="verification_code"
          label="验证码"
          placeholder="请输入验证码"
        >
          <template #button>
            <div class="verode" @click="getVercode">{{ codemessage }}</div>
          </template>
        </van-field>
      </div>
      <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="store_name"
          label="商家名称"
          placeholder="请输入商家名称"
        />
      </div>
      <div class="inputbox marginT16">
        <van-field label-align="right" label-width="60" v-model="linkman" label="联系人" placeholder="请输入联系人" />
      </div>
      <div class="inputbox marginT16">
        <van-field
          label-align="right"
          label-width="60"
          v-model="user_nickname"
          label="后台账号"
          placeholder="请输入后台账号"
        />
      </div>
      <div class="inputbox marginT16">
        <van-field
          type="password"
          label-align="right"
          label-width="60"
          v-model="user_pass"
          label="确认密码"
          placeholder="请输入确认密码"
        />
      </div>
      <div class="headlogo marginT16">
        <div>头像LOGO</div>
        <div class="uploadbox">
          <van-uploader v-model="fileList" :max-count="1" :max-size="5000 * 1024" :after-read="clickUpload">
          </van-uploader>
        </div>
      </div>
      <div class="textareabox marginT16">
        <van-field
          rows="3"
          label-align="right"
          label-width="60"
          v-model="desc"
          label="商家描述"
          type="textarea"
          placeholder="请输入文字"
        />
      </div>
      <div class="agreement marginT16">
        <van-checkbox v-model="checked" shape="square" checked-color="#FF7220">
          <div class="agreetxt">
            <div class="tip1">我已知悉商家</div>
            <div class="tip2" @click.stop="lookAgree">《合作协议》</div>
          </div>
        </van-checkbox>
      </div>
      <div class="btnbottom">
        <div class="btn" @click="showpwd = true">立即申请</div>
      </div>
    </div>
    <van-dialog v-model="showpwd" title="申请商家" @cancel="pwdShow" @confirm="clickApply" show-cancel-button>
      <van-field
        type="password"
        v-model="password"
        name="安全密码"
        label="安全密码"
        placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
    <agreetxt ref="agreetxt"></agreetxt>
  </div>
</template>

<script>
import Agreetxt from "./actionsheet/agreetxt.vue";
import { applyStore, uploadimg, applyConfig, getCode } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "EtWebApplyshop",
  components: {
    MallheaderVue,
    Agreetxt,
  },
  data() {
    return {
      checked: false,
      imgurl: "",
      fileList: [],
      mobile: "",
      verification_code: "",
      store_name: "",
      linkman: "",
      logo: "",
      desc: "",
      user_nickname: "",
      user_pass: "",
      codemessage: "获取验证码",
      disabled: false,
      depositConfig: {
        mall_ya_coin: "ATT",
        mall_ya_num: "10",
      },
      showpwd: false,
      password: "",
      address: "",
      userInfo: {},
    };
  },

  mounted() {},
  activated() {
    this.requestApplyConfig();
    this.address = sessionStorage.getItem("address");
    this.userInfo = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};

  },
  methods: {
    pwdShow() {
      this.showpwd = false;
    },
    requestApplyConfig() {
      applyConfig().then((res) => {
        this.depositConfig = res.data;
          Dialog.confirm({
              title: "注意",
              message: "请先申请商家，再销毁"+this.depositConfig.mall_ya_num+this.depositConfig.mall_ya_coin+"后审核通过后可成为商家。",
              confirmButtonText: "继续",
              confirmButtonColor: "#FF7220",
          })
              .then(() => {
                  // on confirm
              })
              .catch(() => {
                  // on cancel
              });
      });
    },
    requestApplyStore() {
      const params = {
        address: this.address,
        pay_code: this.password,
        mobile: this.mobile,
        verification_code: this.verification_code,
        store_name: this.store_name,
        linkman: this.linkman,
        logo: this.logo,
        desc: this.desc,
        user_nickname: this.user_nickname,
        user_pass: this.user_pass,
      };
      applyStore(params).then((res) => {
        let success = res.success;
        if (success) {
          this.$router.go(-1);
        } else {
          Toast(res.msg);
        }
      });
    },
    lookAgree() {
      console.log("lookAgree");
      this.$refs.agreetxt.preview("merchant_agreement");
    },
    getVercode() {
      if (this.disabled) {
        return;
      }
      if (this.mobile.length === 0) {
        Toast("请输入手机号");
        return;
      }
      const params = {
        address: this.address,
        mobile: this.mobile,
      };
      getCode(params).then((res) => {
        if (res.code == 200) {
          this.disabled = true;
          Toast("发送成功");
          this.codemessage = 60;
          this.timer();
        } else {
          Toast("今日发送已达上限");
        }
      });
    },
    timer() {
      let _this = this;
      if (this.codemessage > 0) {
        setTimeout(function() {
          _this.codemessage--;
          _this.timer();
        }, 1000);
      } else {
        this.codemessage = "获取验证码";
        this.disabled = false;
      }
    },
    clickApply() {
      let _this = this;
      if (!this.checked) {
        Toast("请阅读合作协议");
        return;
      }
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return;
      }
      if (this.address && this.userInfo.has_paypwd != 1) {
        Toast("请先完善资料,设置安全密码");
        setTimeout(function() {
          _this.$router.push({
            path: "/home/mine",
          });
        });
        return;
      }
      this.requestApplyStore();
      // this.$router.push({
      //   path: "/mall/applyprompt",
      // });
    },
    clickUpload(file) {
      console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      const fd = new FormData();
      fd.append("file", file.file);
      uploadimg(fd)
        .then((res) => {
          this.logo = res.data.http_url;
          file.status = "done";
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT16 {
  margin-top: 16px;
}
.applycontent {
  background: #ffffff;
  min-height: 100vh;
  padding: 0 32px 20px 32px;
  .inputbox {
    width: 686px;
    height: 84px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    .verode {
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      color: rgba(255, 114, 32, 1);
    }
    ::v-deep .van-cell {
      background-color: transparent;
      line-height: 20px;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .headlogo {
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
    font-weight: 700;
    font-family: "PingFang SC";
    line-height: 36px;
    .uploadbox {
      margin-top: 8px;
    }
  }
  .textareabox {
    width: 686px;
    min-height: 156px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    ::v-deep .van-cell {
      background-color: transparent;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .agreement {
    .agreetxt {
      display: flex;
      .tip1 {
        color: rgba(89, 89, 89, 1);
        font-size: 28px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 44px;
      }
      .tip2 {
        color: rgba(255, 114, 32, 1);
        font-size: 28px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 44px;
      }
    }
  }
  .btnbottom {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .btn {
      width: 524px;
      height: 72px;
      border-radius: 74px;
      opacity: 1;
      background: rgba(255, 114, 32, 1);
      color: rgba(238, 238, 238, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 72px;
    }
  }
}
</style>
